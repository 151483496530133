<template>
    <div class="footer">
        <v-footer color="black" padless absolute>
            <v-col class="grey darken-4 py-4 text-center white--text">
                <strong>Heartwood Carpentry © - {{ new Date().getFullYear() }}</strong>
            </v-col>
        </v-footer>
    </div>
</template>

<script>
export default {
    name: 'FooterWrapper',
};
</script>

<style scoped>
.footer {
    padding-top: 66px;
}
</style>
