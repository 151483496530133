<template>
    <div class="home">
        <h4>
            Joe specializes in finer interior and exterior woodworking. Traditional designs and reproductions of
            original period pieces are his specialty. He also has extensive experience in new builds and restorations.
        </h4>
        <br />
        <image-grid :images="images" :should-change-if-mobile="true" />
        <h4>
            Joe has enjoyed working in carpentry since the early 80's and is still impassioned with the same sense of
            amazement about the fine craft and its future.
        </h4>
        <br />

        <h4>
            He has sensitivity to form and function while maintaining both scale and proportion, to maintain the
            esthetics of the project.
        </h4>
        <br />

        <h4>
            He has often been quoted to say, “If I am pleased and satisfied with the final results then my customers are
            usually ecstatic.” To put it frankly, Joe cares about his craftsmanship and it is reflected in his attention
            to all the details.
        </h4>
        <br />
        <br />
        <h4>
            “I work not to just please my customers / clients but to satisfy my own standards as well. No one is in
            business to suffer gratuitous losses, but some prefer not to subsidize a profit by sacrificing the pleasure
            and virtue of classic woodwork to a lifeless, production – driven methodology.” Joseph Beals
        </h4>
    </div>
</template>

<script>
import ImageGrid from './components/ImageGrid';
export default {
    name: 'Home',
    components: { ImageGrid },
    data() {
        return {
            // 'https://static.heartwoodcarpentry.org/homepage/hp4.jpeg', uglys
            images: [
                {
                    id: 0,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp1.jpeg',
                },
                {
                    id: 1,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp2.jpeg',
                },
                {
                    id: 2,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp3.jpeg',
                },
                {
                    id: 3,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp5.jpeg',
                },
                {
                    id: 4,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp6.jpeg',
                },
                {
                    id: 5,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp7.jpeg',
                },
                {
                    id: 6,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp8.jpeg',
                },
                {
                    id: 7,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp9.jpeg',
                },
                {
                    id: 8,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp10.jpeg',
                },
                {
                    id: 9,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp11.jpeg',
                },
                {
                    id: 10,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp12.jpeg',
                },
                {
                    id: 11,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp13.jpeg',
                },
                {
                    id: 12,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp14.jpeg',
                },
                {
                    id: 13,
                    url: 'https://static.heartwoodcarpentry.org/homepage/hp15.jpeg',
                },
            ],
        };
    },
};
</script>
<style>
h4 {
    color: white;
    text-align: left;
    font-weight: bolder;
}
.home {
    padding: 6% 10%;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
}
</style>
