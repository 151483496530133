<template>
    <div class="columns">
        <div class="col_desktop" v-if="isDesktop || !shouldChangeIfMobile">
            <v-item-group align="center">
                <v-container>
                    <v-row class="home_images_row">
                        <v-col v-for="{ url, id } in images" :key="id" cols="6" md="4">
                            <div class="home_images">
                                <v-img
                                    :src="url"
                                    height="250"
                                    width="250"
                                    class="header_image"
                                    alt="Image"
                                    @click="handleClick(id)"
                                />
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-item-group>

            <v-dialog class="desktop_car" v-model="isImageModalActive" transition="scale-transition" width="auto">
                <v-carousel v-model="imageIndexOffset" hide-delimiters hide-delimiter-background height="auto">
                    <image-carousel :images="images" />
                </v-carousel>
            </v-dialog>
        </div>
        <div class="col_mobile" v-else>
            <v-carousel hide-delimiters hide-delimiter-background>
                <image-carousel :images="images" />
            </v-carousel>
        </div>
    </div>
</template>

<script>
import ImageCarousel from './ImageCarousel';
export default {
    name: 'ImageGrid',
    components: { ImageCarousel },
    props: {
        images: {
            type: Array,
            required: true,
        },
        shouldChangeIfMobile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            windowWidth: 0,
            imageIndexOffset: 0,
            isImageModalActive: false,
        };
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.handleResizeEvent);
            this.handleResizeEvent();
        });
    },
    computed: {
        isDesktop() {
            return this.windowWidth >= 600;
        },
    },
    methods: {
        handleResizeEvent() {
            this.windowWidth = window.innerWidth;
        },
        handleClick(index) {
            this.imageIndexOffset = index;
            this.isImageModalActive = true;
        },
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResizeEvent);
    },
};
</script>

<style scoped>
.header_image {
    cursor: grab;
}

.columns {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.col_mobile {
    width: 80vw;
}
</style>
