<template>
    <div class="img_carousel">
        <v-carousel-item v-for="{ id, url, caption } in images" :key="id">
            <v-card max-height="1300" max-width="500">
                <v-img :src="url" class="c_image" alt="gallery_image" />
                <v-card-subtitle v-if="caption"> {{ caption }}</v-card-subtitle>
            </v-card>
        </v-carousel-item>
    </div>
</template>

<script>
export default {
    name: 'ImageCarousel',
    props: {
        images: {
            type: Array,
            required: true,
        },
        maxHeight: {
            type: Number,
            default: 1200,
        },
    },
};
</script>

<style scoped></style>
