import { render, staticRenderFns } from "./ImageCarousel.vue?vue&type=template&id=f6bf7186&scoped=true&"
import script from "./ImageCarousel.vue?vue&type=script&lang=js&"
export * from "./ImageCarousel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6bf7186",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VCardSubtitle,VCarouselItem,VImg})
