<template>
    <div class="header">
        <div class="banner">
            <v-img :src="headerUrl" class="header_image" alt="Heartwood_logo" />
        </div>
        <div class="menu">
            <router-link class="router_link" to="/">HOME</router-link>
            <router-link class="router_link" to="/gallery">GALLERY</router-link>
            <router-link class="router_link" to="/contact">CONTACT</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MenuWrapper',
    data: () => {
        return {
            headerUrl: 'https://static.heartwoodcarpentry.org/header.jpeg',
        };
    },
};
</script>

<style scoped>
.header_image {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.banner {
    background-color: rgba(243, 238, 224);
}
.router_link {
    color: white;
    padding: 5%;
    font-weight: 900;
    text-decoration: none;
}
.router_link:hover {
    color: #9b9b9b;
}
.menu {
    padding-top: 5%;
}
</style>
