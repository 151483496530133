import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/gallery',
        name: 'Gallery',
        component: () => import('../views/Gallery'),
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact'),
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => import('../views/components/NotFound'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: '/',
    scrollBehavior: (to, from, savedPosition) => savedPosition || { x: 0, y: 0 },
    routes,
});

export default router;
