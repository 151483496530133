<template>
    <v-app id="app">
        <menu-wrapper />
        <router-view />
        <footer-wrapper />
    </v-app>
</template>

<script>
import MenuWrapper from './views/components/MenuWrapper';
import FooterWrapper from './views/components/FooterWrapper';
export default {
    name: 'App',
    components: { FooterWrapper, MenuWrapper },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    /*background: black;*/
    background-color: rgb(18, 17, 17);
    min-height: 100vh;
}
body {
}
</style>
